import React, { Component } from "react";
import "./Experience.css";
import { Fade } from "react-reveal";
import IncOneLogo from "../../assests/images/incone.png";
import SteelEyeLogo from "../../assests/images/se.png";
import AcrylicLogo from "../../assests/images/acry.png";

class ExpSection extends Component {
  render() {
    return <div></div>;
  }
}

export default ExpSection;
